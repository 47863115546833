export const data = {
  name: "杭州师范大学",
  campus: [
    {
      name: "仓前校区",
      href: "https://isaleonline.zjtelecom.com.cn/order-front-campus/#/school/enterPage?serialNum=91fe647e39d2a0e2628f74d8661d7694",
    },
    {
      name: "下沙校区",
      href: "https://isaleonline.zjtelecom.com.cn/order-front-campus/#/school/enterPage?serialNum=a240881635ecfb09fb4b284a2779db8b",
    },
  ],
};

export const tip = "";
